import { Component } from "react";
import { IoStar, IoStarOutline, IoStarHalf } from 'react-icons/io5';


export default class PriorityStarBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            priority : props.p.priority,
            top: props.p.top
        }
        this.updatePriority.bind();
    }
    
    updatePriority(p) {
        this.setState( {
            priority : p.priority,
            top : p.top
        });
    }
    
    render() {
        let p = (this.state)
         return (
             <span>
                 {(p.priority > 0 && p.top === true) && <IoStar />}
                 {(p.priority > 0 && p.top === false) && <IoStarHalf />}
                 {(p.priority === 0 && p.top === true) && <IoStarHalf />}
                 {(p.priority === 0 && p.top === false) && <IoStarOutline />}
             </span>
         )
        
    }
}