import {Component} from "react";
import { Button } from "react-bootstrap";
import DeleteBtn from "./components/DeleteBtn";
import ExportBtn from "./components/ExportBtn";
import LockBtn from "./components/LockBtn";
import PriorityStarBtn from "./components/PriorityStarBtn";
import ShowHideBtn from "./components/ShowHideBtn";
import TitleBox from "./components/TitleBox";

class ActionBar extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        console.log("render");
        const note = (this.props.note)
        return (
            <div className="row" style={{background : "wheat", padding : "5px"}}>
                <div className="col-3">
                    <TitleBox title = {note.title} />
                </div>
                <div className="col-9" style={{textAlign : "right"}}>
                    <PriorityStarBtn p = {{priority: note.priority, top: note.top}} />
                    &nbsp;&nbsp;
                    <LockBtn pass = {note.pass} />
                    &nbsp;&nbsp;
                    <ExportBtn note = {note}/>
                    &nbsp;&nbsp;
                    <ShowHideBtn hidden = {note.hidden} />
                    &nbsp;&nbsp;
                    <DeleteBtn id = {note.id} />
                </div>
            </div>
        );
    }
}

export default ActionBar;