import { Component } from "react"
import { GiPadlockOpen, GiPadlock } from 'react-icons/gi';

export default class LockBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pass : props.pass
        }
    }
    
    render() {
        let pass = this.state.pass;
        return (
            <span>
                {(pass === "") &&  <GiPadlockOpen />}
                {(pass !== "") &&  <GiPadlock />}
            </span>
        )
    }
}