import JotterDetail from "./JotterDetail";
import JotterListViewer from "./JotterListViewer";


export default function HomePage() {
    
    return (
      <div className="row">
          <div className="col-3">
              <JotterListViewer />
          </div>
          <div className="col-9">
              <JotterDetail />
          </div>
      </div>  
    );
    
}