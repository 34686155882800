import { Component } from "react";


export default class TitleBox extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            title : props.title
        }
        
    }
    
    render() {
        return (
          <span>
              <input type="text" value={this.state.title} 
              onChange = {(e) => this.setState({title : e.target.value})}
              />
          </span>  
        );
    }
    
}