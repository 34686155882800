import { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';

export default function ShowHideBtn(note) {
    const [isHidden, setIsHidden] = useState(note.hidden)
    
    const updateHidden = ()=>{
        console.log("isHidden :: " + isHidden);
        setIsHidden(!isHidden);
    }
    
    return (
        <span onClick={updateHidden}>
           {(isHidden === true) ? <BiHide /> : <BiShow /> } 
        </span>
    )
}