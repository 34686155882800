import { MdDelete } from 'react-icons/md';

export default function DeleteBtn(note) {
    
    const delNote = () => {
        alert("We have deleted Jotter Id : " + note.id);
    }
    
    return (
        <span onClick={delNote}>
            <MdDelete />
        </span>
    )
}