import ActionBar from "./editViewBar/ActionBar";

export default function JotterDetail() {
    return (
        <div className="row">
            <div className="col-12">
            <ActionBar note = {{
                id: 1
                , title: "Note1"
                , priority: 0
                , top: false
                , pass: ""
                , hidden: false
            }} />
            </div>
        </div>
    )
}